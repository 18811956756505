$de-blue : #1E3CD6;

body.login {
  background-color: #FFF;
}

%hover-and-focus-color {
  border-color: $de-blue;
  box-shadow: 0 0 2px $de-blue;
}

#login {
  h1 a {
    &:focus{
      @extend %hover-and-focus-color;
    }
    background-image: url('./../images/logo.svg');
    background-size: 320px;
    width: 320px;
    margin-bottom: 10px;
  }
}

#loginform {
  #user_login {
    &:focus{
      @extend %hover-and-focus-color;
    }
  }

  #user_pass {
    &:focus {
      @extend %hover-and-focus-color;
    }
  }

  #rememberme {
    &:focus {
      @extend %hover-and-focus-color;
    }
  }
  p.submit {
    input {
      background: $de-blue;
      border-color: $de-blue;
      box-shadow: 0 0 2px $de-blue;
    }
  }
}

#nav {
  a {
    &:hover {
      color: $de-blue !important;
    }
  }
}

#backtoblog {
  a {
    &:hover {
      color: $de-blue !important;
    }
  }
}

a.privacy-policy-link{
  color: $de-blue;
}
